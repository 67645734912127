import React from 'react'
import { AsyncLogo } from '../components/AsyncLogo'
import { SectionTwoThirdsRight } from '../components/SectionTwoThirdsRight'

export const WhatWeDo = () => {
  return (
    <SectionTwoThirdsRight label="WHAT WE DO">
      <div className="f4 f3-ns mt2 mt0-ns">Data―driven Art + Design</div>
      <div className="f5 f4-ns">
        <div className="mt3">
          Our studio partners with ambitious clients like Starbucks, IBM, MOMA and Google to turn
          data into stunning{' '}
          <span className="underline-gray-light">digital and physical experiences. </span>
        </div>
        <div className="mt3">
          We design visualizations, artwork, and software that make large quantities of data
          intelligible and captivating.
        </div>
      </div>
    </SectionTwoThirdsRight>
  )
}

export const WhatWeDoEnterprise = () => {
  return (
    <SectionTwoThirdsRight label="WHAT WE DO">
      <div className="f4 f3-ns mt2 mt0-ns">Data Science & Enterprise Analytics</div>
      <div className="f5 f4-ns">
        <div className="mt3">
          Our studio partners with ambitious clients like IBM, JP Morgan Chase and Deloitte to turn
          data into enterprise-grade software that reliably generates insights, revenue, and value.
        </div>
        <div className="mt3">
          We do that across industries by making large quantities of data intelligible and
          actionable, and helping you imagine new data-driven solutions and opportunities.
        </div>
      </div>
    </SectionTwoThirdsRight>
  )
}

export const Clients = ({ logos, isBorderBottom, className = '' }) => {
  const logoPaths = logos.map(d => `images/${d}_LOGO.svg`)
  const borderBottom = isBorderBottom ? 'b--light-gray bb' : ''

  return (
    <div className={`${className}`}>
      <SectionTwoThirdsRight label="CLIENTS">
        <div className="f5 f4-ns">
          We helped more than <span className="underline-gray-light">100 global organizations</span>{' '}
          and <span className="underline-gray-light">30 digital startups</span> turn data into a
          resource to drive awareness, engagement, comprehension and insight.{' '}
        </div>
        <a
          className="link f6 underline mt3"
          style={{ textUnderlinePosition: 'under' }}
          href={'/clients'}
        >
          {'See All Clients'} &#8599;
        </a>
      </SectionTwoThirdsRight>
      <div className={`pb5-ns pb4 mt3 mt5-ns logos-grid-large logos-grid-small ${borderBottom}`}>
        {logoPaths.map((url, i) => (
          <AsyncLogo className="w5-ns h5-ns mv3 db-ns flex flex-center h3" url={url} key={i} />
        ))}
      </div>
    </div>
  )
}

export const OurSolutions = ({ className = '' }) => {
  return (
    <SectionTwoThirdsRight className={className} label="OUR SOLUTIONS">
      <div className="f4 f3-ns mt2 mt0-ns">
        Our standard solutions to manage your data and increase your business
      </div>
      <div className="f5 f4-ns">
        <div className="mt3">
          At Accurat we complement our award-winning design consulting experience with a mix of
          technologies and highly specialized teams to support organizations with cost and time
          effective solutions to their data analysis and reporting problems. Our solutions are
          deployed within our consulting engagements or in addition to them, and combine custom
          software libraries, proprietary and open data, and our data visualization expertise to
          automate analysis and reporting across your organization.
        </div>
      </div>
      <a
        className="link f6 underline mt3"
        style={{ textUnderlinePosition: 'under' }}
        href={'/contact/'}
      >
        {'Write Us'} &#8599;
      </a>
    </SectionTwoThirdsRight>
  )
}
