import React from 'react'

export const FollowUsModule = () => (
  <div className="bg-light-gray">
    <div className="mw7-m mw8-l center ph3 flex flex-row-ns flex-column items-center">
      <div className="w-66-ns w-100">
        <h5 className="mv4 mv5-ns f5 ttu tracked mono normal">Follow us</h5>
      </div>
      <div className="flex w-33-ns w-100 pv4-ns pb4 pv0-l justify-start">
        <a
          className="pr4"
          href="https://twitter.com/accuratstudio"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/tw.svg" height="25" alt="" />
        </a>
        <a
          className="pr4"
          href="https://www.instagram.com/accuratstudio"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/ig.svg" height="25" alt="" />
        </a>
        <a
          className="pr4"
          href="https://www.facebook.com/accurat.it"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/fb.svg" height="25" alt="" />
        </a>
        <a
          className="pr4"
          href="https://medium.com/accurat-studio"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/Medium.svg" height="25" alt="" />
        </a>
      </div>
    </div>
  </div>
)
