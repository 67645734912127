import React, { useState, useEffect } from 'react'

const Logos = require.context('../../static/images', true, /\LOGO.(svg)(\?.*)?$/)

const remapLogoUrl = url => url.replace('images', '.')

export const AsyncLogo = ({ url, className }) => {
  const [Component, setComponent] = useState(null)
  useEffect(() => {
    const NewComponent = Logos(remapLogoUrl(url)).default
    setComponent(() => NewComponent)
  }, [])

  if (!Component) return null
  return <Component className={className} />
}
